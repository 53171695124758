.sp-postimage {
    margin-right: 25px;
}

.complex {
    &_header, &_service {
        border-bottom: 1px solid #9c9c9d;
    }
    &_header {
        text-transform: none!important;
    }
    &_list { 
        margin: 20px 0 30px 30px ; 
        list-style-type: disc;
        font-family: Subaru-Light, sans-serif; 
    }
    &_service {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 10px 10px;
    }
}

.contacts-section-input11 input, .contacts-section-input2 input,
.contacts-section-input input, .contacts-section-textarea textarea {
    border:none;
    border-bottom: 1px solid #9c9c9d;
    border-radius: 0;
}
.contacts-section-input11 input.subbtn1,
.contacts-section-input2 input.subbtn {
    width: 230px!important;
    padding: 10px;
    text-align: center;
    border: none;
    background:#e4661b!important;
}
.w-70 {
    width: 70%!important;  
}

.banner-attention {
    width: 70%;
    margin: 0 auto 50px;
    font-family: "Subaru-Black", sans-serif;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-size: 16px;
    padding: 16px 0;
    background: #d2804f;
    text-align: center;
    color: white;
}
